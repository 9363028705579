<template>
    <template v-for="(toasts, container) in state.containers" :key="container">
        <div v-if="state.backdrop" style="z-index: 2500;" class="overlay"></div>
        <div class="o365-toasts toast-container p-3 position-fixed top-0 start-0" :class="[container, {'has-navbar': hasNavbar}]">
            <template v-for="toast in toasts.sort((a, b) => {if(a.value.type>b.value.type){return -1} if(a.value.type>b.value.type){return 1} return 0})" :key="toast.uid">
                <template v-if="toast.type === 'base'">
                    <Toast class="o365-toast" :bootstrapOptions="toast.value.toastOptions" :toastOptions="toast.options" :type="toast.value.type" :remove="() => {state.remove(toast.uid)}">
                        <ToastContent v-bind="toast.value"  :instance="toast.instance" :slimVersion="toast.value.toastOptions.slimVersion"  />
                    </Toast>
                </template>
                <template v-else-if="toast.type === 'custom'">
                    <Toast :class="toast.value.toastClass" :bootstrapOptions="toast.value.toastOptions" :toastOptions="toast.options" :remove="() => {state.remove(toast.uid)}">
                        <component :is="toast.value.component" v-bind="toast.value.props"></component>
                    </Toast>
                </template>
            </template>
        </div>
    </template>
</template>

<script setup lang="ts">
import Toast from './ToastService.Toast.vue';
import ToastContent from './ToastService.ToastContent.vue';
import { inject } from 'vue';

const ToastService: any = inject('ToastService');

const state = ToastService.getToastService().state;
const hasNavbar = window.document.getElementById('main-nav') != null;
</script>

<style scoped>
.o365-toasts {
    z-index: 2500; 
}

.o365-toast {
    min-width: 350px; 
    width: auto;
    max-width: 700px;
}

@media only screen and (max-width: 350px) {
    .o365-toast {
        min-width: 250px; 
        width: auto;
        max-width: 350px;
    }
}

.o365-toast-icon {
    font-size: 1.5em; 
    line-height: .04167em;
    vertical-align: -.125em;
}

.o365-toasts-left-top {
    left: 0!important;
    top: 0!important;
}
.o365-toasts-left-top.has-navbar {
    top: 50px!important;
}
.o365-toasts-right-top {
    left: unset!important;
    right: 0!important;
    top: 0!important;
}
.o365-toasts-right-top.has-navbar {
    top: 50px!important;
}

.o365-toasts-confirm {
    left: 50%!important;
    top: 10%!important;
    transform: translateX(-50%)!important;
}
</style>
